











import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import Calendar from '@/common/calendar.vue'
import EventSearchResult from '@/components/search-results/EventSearchResult.vue'
import CalendarContainer from '@/components/forms/CalendarContainer.vue'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import { hasRight } from '@/utils/rights'
import Rights from '@/common/rights'
@Component({
  components: { ConfirmationModal, CalendarContainer, EventSearchResult, Calendar }
})
export default class CalendarAdministration extends Vue {
  get writeable (): boolean {
    return hasRight(Rights.CREATE_EVENT)
  }

  get manage (): boolean {
    return hasRight(Rights.MANAGE_EVENT)
  }

  get bookmarkable (): boolean {
    return hasRight(Rights.BOOKMARK_EVENT)
  }
}
